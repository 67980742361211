import React from 'react';
import Moment from 'react-moment';

import moment from 'moment'

import {Calendar} from 'primereact/calendar';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

class DialogDeliveryTank extends React.Component {

  constructor(props) {
    super(props);

    var defaultModel = {
      id: this.props.deliveryTank?.id || null,
      delivery: this.props.delivery || {},

      date_created: moment(this.props.deliveryTank?.date_created).toDate() || '',
      tank: (this.props.deliveryTank?.tank === null) ? '' : this.props.deliveryTank?.tank,
      temperature: this.props.deliveryTank?.temperature || '',
      volume: this.props.deliveryTank?.volume || '',
      volume_tank: this.props.deliveryTank?.volume_tank || '',
      volume_additive_1: this.props.deliveryTank?.volume_additive_1 || '',
      volume_additive_remaining_1: this.props.deliveryTank?.volume_additive_remaining_1 || '',
      volume_additive_2: this.props.deliveryTank?.volume_additive_2 || '',
      volume_additive_remaining_2: this.props.deliveryTank?.volume_additive_remaining_2 || '',
    }
    this.state = Object.assign({}, defaultModel, props.detail);

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.renderEditField = this.renderEditField.bind(this);
  }

  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value || '' } );
  }

  renderEditField(field, title, keyfilter) {
    return (
      <span className="p-float-label dialog-field">
          <InputText name={field} value={this.state[field]}
           onChange={this.inputChangeHandler} keyfilter={keyfilter} style={{width: '250px'}}/>
          <label htmlFor={field}>{title}</label>
      </span>
    )
  }

  render() {
    // ensure required fields
    const { tank, delivery, volume } = this.state;
    const isValid = delivery && (tank !==  '') && volume && volume > 0 ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.saveDeliveryTank(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Livraison cuve"
          onHide={this.props.onHide}
          visible={this.props.visible}
          style={{ width: '50vw' }}
          className='dialog-date-picker'
          footer={footer}>

          <div>
            Modifer livraison cuve sur livraison {this.state.delivery?.machine?.number} @ {' '}
            <Moment format="DD/MM/YYYY">
              {this.state.delivery?.date_start}
            </Moment>
          </div>

          <span className="p-float-label dialog-field">
            <Calendar
              name='date_created'
              value={this.state.date_created}
              onChange={this.inputChangeHandler}
              style={{width: '250px', 'marginTop': '2em'}}
              showIcon
              required={true}
              appendTo={document.body}
              touchUI
              showSeconds={false}
              showTime hourFormat="24"
              dateFormat="dd/mm/yy"
              placeholder="Date *"
            />
          </span>

          {this.renderEditField('tank', 'Numero cuve *', 'pint')}
          {this.renderEditField('volume', 'Volume livré (L) *', 'pnum')}
          {this.renderEditField('volume_tank', 'Volume cuve (L)', 'pnum')}
          {this.renderEditField('temperature', 'Temperatue (C)', 'pnum')}
          {this.renderEditField('volume_additive_1', 'Additif 1 (L)', 'pnum')}
          {this.renderEditField('volume_additive_remaining_1', 'Additif 1 restant (L)', 'pnum')}
          {this.renderEditField('volume_additive_2', 'Additif 2 (L)', 'pnum')}
          {this.renderEditField('volume_additive_remaining_2', 'Additif 2 restant (L)', 'pnum')}

        </Dialog>

      </div>
    );
  }
}

export default DialogDeliveryTank
