import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

class DialogSite extends React.Component {

  constructor(props) {
    super(props);
    var defaultModel = {
      id: null,
      name: ''
    }
    this.state = Object.assign({}, defaultModel, props.detail);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.renderEditField = this.renderEditField.bind(this);
  }

  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value } );
  }

  renderEditField(field, title) {
    return (
      <span className="p-float-label dialog-field">
          <InputText name={field} value={this.state[field]} onChange={this.inputChangeHandler} />
          <label htmlFor={field}>{title}</label>
      </span>
    )
  }

  render() {
    // ensure required fields
    const { name } = this.state;
    const isValid = name ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.saveSite(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Site"
          onHide={this.props.onHide}
          visible={this.props.visible}
          className={"dialog"}
          footer={footer}>

          {this.renderEditField('name', 'Nom')}

        </Dialog>

      </div>
    );
  }
}

export default DialogSite
