import React from 'react';
import Moment from 'react-moment';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {ObjectUtils} from 'primereact/utils';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';

class Services extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      servicesLoading: false,
      alertsLoading: false,
      services: [],
      alerts: [],
    };
    this.Api = new Api();

  }

  componentDidMount() {
    toggleMenu(false)
    this.refreshAlertsList()
    this.refreshServicesList()
  }

  refreshAlertsList() {
    this.setState({alertsLoading: true});

    this.Api.machines.alerts().then((data) => {
      this.setState({alerts: data})
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({alertsLoading: false});
    });
  }

  refreshServicesList() {
    this.setState({servicesLoading: true});

    this.Api.services.get().then((data) => {
      this.setState({services: data})
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({servicesLoading: false});
    });
  }

  /*
  template for company name / link
  */
  companyTemplate(rowData, column) {
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          <a href={'/#/company/' + rowData['company_id'] + '/'}>{rowData['company_name']}</a>
      </React.Fragment>
    )
  }

  /*
  Generic cell template that includes the responsive column heading for small screens
  */
  genericTemplate(rowData, column) {
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          {ObjectUtils.resolveFieldData(rowData, column.field)}
      </React.Fragment>
    );
  }

  /*
  format incoming ISO/UTC date to human-friendly
  */
  dateTemplate(rowData, column) {
    if (rowData[column.field] == null) return ''
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          <Moment format="DD/MM/YYYY HH:mm">{rowData[column.field]}</Moment>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <div className='table-title'>
          <label>Alerts</label>
        </div>

        <p>Machines qui n'ont pas recu un message 'coucou' dans les derniers 24 heures</p>

        <DataTable
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Items {first} - {last} de {totalRecords}" rows={100}
          rowsPerPageOptions={[100,200,500]}
          paginator
          sortField="date_created"
          sortOrder={-1}
          loading={this.state.alertsLoading}

          value={this.state.alerts}
          responsive={true} >

          <Column field="company_name" header="Company" sortable={true} filter={true} filterMatchMode="contains" body={this.companyTemplate.bind(this)}/>
          <Column field="site_name" header="Site" sortable={true} filter={true} filterMatchMode="contains" body={this.genericTemplate.bind(this)}/>
          <Column field="machine_number" header="Machine" sortable={true} filter={true} filterMatchMode="contains" body={this.genericTemplate.bind(this)}/>
          <Column field="date_created" header="Dernier recu" body={this.dateTemplate.bind(this)} sortable={true}/>

        </DataTable>

        <div className='table-title' style={{'marginTop': '40px'}}>
          <label>Maintenance</label>
        </div>

        <DataTable
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Items {first} - {last} de {totalRecords}" rows={100}
          rowsPerPageOptions={[100,200,500]}
          paginator
          sortField="date_created"
          sortOrder={-1}
          loading={this.state.servicesLoading}

          value={this.state.services}
          responsive={true} >

          <Column field="site_name" header="Site" sortable={true} filter={true} filterMatchMode="contains" body={this.genericTemplate.bind(this)} />
          <Column field="machine_number" header="Machine" sortable={true} filter={true} filterMatchMode="contains" body={this.genericTemplate.bind(this)} />
          <Column field="date" header="Date" sortable={true} filter={true} body={this.dateTemplate.bind(this)} filterMatchMode="contains" />
          <Column field="date_created" header="Date recu" body={this.dateTemplate.bind(this)} sortable={true}/>
          <Column field="text" header="Message" sortable={true} filter={true} filterMatchMode="contains" body={this.genericTemplate.bind(this)}/>

        </DataTable>
      </div>
    )
  }
}

export default Services;
