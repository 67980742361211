import React from 'react';

import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown';
import {AutoComplete} from 'primereact/autocomplete';

import Api from '../utils/Api';
import {isAdmin} from '../utils/utils.js'
import {processError} from '../utils/errors';

class LogitisticsUpdate extends React.Component {

  constructor(props) {
    super(props);
    var delivery= props.delivery;
    this.state = {
      fuel_seller: delivery.fuel_seller || {name: ''},
      fuel_deliverer: delivery.fuel_deliverer || {name: ''},
      fuel_deliverer_reg_number: delivery.fuel_deliverer_reg_number || '',
      fuel_seller_suggestions: [],
      fuel_sellers: [],
      fuel_deliverer_suggestions: [],
      fuel_deliverers: [],
      fuel_deliverer_reg_number_suggestions: [],
    }
    this.Api = new Api();
    this.suggestRegNumbers = this.suggestRegNumbers.bind(this);
  }

  componentDidMount() {
    if (!isAdmin()) return ;
    this.refreshFuelContractors();
  }

  refreshFuelContractors() {
    this.Api.fuelSellers.list().then((data) => {
      // We can't consume the sellers directly because they need to have *exactly* the same
      // keys as those found in the Delivery serializer (i.e. minus the 'count' properties.
      // For this reason, build a new list with only the required fields.
      let contractors = [];
      data.map((seller) => {
        contractors.push({id: seller.id, name: seller.name});
      })
      this.setState({fuel_sellers: contractors});
    }).catch((error) => {
      processError(error, this);
    });

    this.Api.fuelDeliverers.list().then((data) => {
      let contractors = [];
      data.map((deliverer) => {
        contractors.push({id: deliverer.id, name: deliverer.name});
      })
      this.setState({fuel_deliverers: contractors});
    }).catch((error) => {
      processError(error, this);
    });
  }

  // filter the total list of fuel_sellers according to the user input
  suggestFuelSellers(event) {
    let results = this.state.fuel_sellers.filter((seller) => {
         return seller.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
    results = results.map((value) => value);
    this.setState({ fuel_seller_suggestions: results });
  }

  // filter the total list of fuel_sellers according to the user input
  suggestFuelDeliverers(event) {
    let results = this.state.fuel_deliverers.filter((deliverer) => {
         return deliverer.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
    results = results.map((value) => value);
    this.setState({ fuel_deliverer_suggestions: results });
  }

  // provide a unique list of fuel deliverer rego numbers for the autocomplete
  suggestRegNumbers(event) {
    this.Api.fuelDelivererRegNumbers.list(event.query).then((data) => {
      this.setState({fuel_deliverer_reg_number_suggestions: data});
    }).catch((error) => {
      processError(error, this);
    });
  }

  render() {
    // this component is only available for admin users
    if (!isAdmin()) return '';

    return (
      <div style={{marginBottom: '20px'}}>
        <Toast ref={(el) => this.toast = el} />

        <h2>Logistiques:</h2>
        <div className="p-grid p-fluid  p-align-end vertical-container">
          <div className="p-sm-12 p-md-6 p-lg-3">
            Vendeur:
            <Dropdown
              value={this.state.fuel_seller}
              options={this.state.fuel_sellers}
              onChange={(event) => this.setState({
                fuel_seller: event.target.value
              })}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Choisir un vendeur"
              appendTo={document.body}
            />
          </div>
          <div className="p-sm-12 p-md-6 p-lg-3">
            Livreur:
            <Dropdown
              value={this.state.fuel_deliverer}
              options={this.state.fuel_deliverers}
              onChange={(event) => this.setState({
                fuel_deliverer: event.target.value
              })}
              optionLabel="name"
              filter
              showClear
              filterBy="name"
              placeholder="Choisir un livreur"
              appendTo={document.body}
            />
          </div>
          <div className="p-sm-12 p-md-6 p-lg-3">
           Livreur nº immatriculation:
           <AutoComplete name='fuel_deliverer_reg_number' value={this.state.fuel_deliverer_reg_number || ''} 
              suggestions={this.state.fuel_deliverer_reg_number_suggestions}
              completeMethod={this.suggestRegNumbers}
              dropdown
              onChange={(event) => this.setState({
                fuel_deliverer_reg_number: event.target.value
              })} />
          </div>
          <div className="p-sm-12 p-md-6 p-lg-3">
            <Button label='Enregistrer' onClick={() => {
              this.Api.deliveries.update(this.props.delivery.id, {
                fuel_seller: this.state.fuel_seller,
                fuel_deliverer: this.state.fuel_deliverer,
                fuel_deliverer_reg_number: this.state.fuel_deliverer_reg_number
              }).then((delivery) => {
                this.toast.show({severity: 'success', summary: 'Succès', detail: 'Livraison mise à jour'});
                this.props.updateDeliveryInState(delivery);
              }).catch((error) => {
                processError(error, this);
              });;
              }
            }/>
          </div>
        </div>
      </div>
    )

  };

}

export default LogitisticsUpdate;
