import React from 'react';
import Moment from 'react-moment';


import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';

class SigFoxLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sigfoxPayload: '',
      decodedMessage: ''
    };
    this.Api = new Api();

    this.decodePayload = this.decodePayload.bind(this);

  }

  componentDidMount() {
    toggleMenu(false);
    this.refreshList()
  }

  refreshList() {
    this.setState({loading: true});

    this.Api.sigfox.get().then((data) => {
      this.setState({messages: data})
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  rowClassName(rowData) {
      return {'p-highlight-pink' : rowData.error};
  }

  /*
  format incoming ISO/UTC date to human-friendly
  */
  dateTemplate(rowData, column) {
    if (rowData[column.field] == null) return ''
    return <Moment format="DD/MM/YYYY HH:mm:ss">{rowData[column.field]}</Moment>
  }

  /*
  decode the given sigfox payload using the server endpoint
  */
  decodePayload() {
    this.Api.sigfox.decodePayload({
      payload: this.state.sigfoxPayload
    }).then((res) => {
      this.setState({decodedMessage: JSON.stringify(res)});
    }).catch(()=>{
      this.setState({decodedMessage: '(problem message)'});
    });
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <div className='table-title'>
          <label>SigFox Log</label>
        </div>

        <div style={{padding: '30px 0px 30px 0px'}}>

          <span className="space-right">
            Decode tool:
          </span>

          <InputText name="name" label="Decode tool:" value={this.state.sigfoxPayload}
            onChange={(e) => this.setState({sigfoxPayload: e.target.value})}
            className="space-right"
            style={{width: '250px'}}
            placeholder="sigfox payload"/>


          <Button className="space-right" label="Decode" onClick={this.decodePayload}/>

          <span style={{color: 'red'}}>
            { this.state.decodedMessage }
          </span>

        </div>

        <DataTable
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Items {first} - {last} de {totalRecords}" rows={100}
          rowsPerPageOptions={[100,200,500]}
          paginator
          sortField="date"
          sortOrder={-1}
          loading={this.state.loading}

          value={this.state.messages}
          responsive={true}
          rowClassName={this.rowClassName}>

          <Column field="date" header="Date" sortable={true} />
          <Column field="body" header="Texte" />
          <Column field="error" header="Erreur" />

        </DataTable>
      </div>
    )
  }
}

export default SigFoxLog;
