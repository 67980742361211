import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';

class DialogUser extends React.Component {

  constructor(props) {
    super(props);
    var defaultModel = {
      id: null,
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      mute_dt_emails: false
    }
    this.state = Object.assign({}, defaultModel, props.detail);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.renderEditField = this.renderEditField.bind(this);

  }


  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value } );
  }


  renderEditField(field, title) {
    return (
      <span className="p-float-label dialog-field">
          <InputText name={field} value={this.state[field]} onChange={this.inputChangeHandler} />
          <label htmlFor={field}>{title}</label>
      </span>
    )
  }

  render() {
    // ensure required fields
    const isValid = this.state.username.length > 2 && this.state.email.length > 0 ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.saveUser(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="User"
          onHide={this.props.onHide}
          visible={this.props.visible}
          className={"dialog"}
          footer={footer}>

          {this.renderEditField('username', 'Username')}
          {this.renderEditField('first_name', 'Prenom')}
          {this.renderEditField('last_name', 'Nom')}
          {this.renderEditField('email', 'Email')}
          {this.renderEditField('phone', 'Phone')}

          <div className="p-field-checkbox" style={{'marginTop': '20px'}}>
            <Checkbox
              inputId="mute_dt_emails"
              checked={this.state['mute_dt_emails']}
              onChange={e => this.setState({ mute_dt_emails: e.checked })}
              className="space-right"
            />
            <label htmlFor="mute_dt_emails">Ne pas recevoir les emails pendant la livraison</label>
          </div>

        </Dialog>

      </div>
    );
  }
}

export default DialogUser
