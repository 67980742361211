import React from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {TabView, TabPanel} from 'primereact/tabview';

import {v1} from 'uuid';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';

import DialogFuelDeliverer from './DialogFuelDeliverer';
import DialogFuelSeller from './DialogFuelSeller';


class FuelDeliverers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      detail: {

      },
      dialogFuelDelivererVisible: false,
      dialogFuelSellerVisible: false,
      fuelDelivererDetail: {},
      fuelSellerDetail: {},
    };

    this.Api = new Api();

    this.loadData = this.loadData.bind(this);

    this.saveFuelDeliverer = this.saveFuelDeliverer.bind(this);
    this.deleteFuelDeliverer = this.deleteFuelDeliverer.bind(this);
    this.hideDialogFuelDeliverer = this.hideDialogFuelDeliverer.bind(this);

    this.saveFuelSeller = this.saveFuelSeller.bind(this);
    this.deleteFuelSeller = this.deleteFuelSeller.bind(this);
    this.hideDialogFuelSeller = this.hideDialogFuelSeller.bind(this);
  }

  componentDidMount() {
    this.loadData()
    toggleMenu(false)
  }

  loadData() {
    this.Api.fuelDeliverers.list().then(deliverers => {
      this.Api.fuelSellers.list().then(sellers => {
        this.setState({
          deliverers: deliverers,
          sellers: sellers
        });
      }).catch((error) => {
        processError(error, this);
      });
    }).catch((error) => {
      processError(error, this);
    });
  }

  ////////////////////////////////////////////////////////////////////////
  // FuelDeliverers

  showDialogFuelDeliverer(fuelDelivererDetail) {
    this.setState({dialogFuelDelivererVisible: true, fuelDelivererDetail: fuelDelivererDetail});
  }

  hideDialogFuelDeliverer() {
    this.setState({dialogFuelDelivererVisible: false});
  }

  saveFuelDeliverer(fuelDelivererDetail) {
    let promise;
    if (!fuelDelivererDetail.id) {
      promise = this.Api.fuelDeliverers.create(fuelDelivererDetail)
    } else {
      promise = this.Api.fuelDeliverers.update(fuelDelivererDetail.id, fuelDelivererDetail)
    }

    promise.then(() => {
      this.hideDialogFuelDeliverer();
      this.loadData();
      this.toast.show({
        severity: 'success',
        summary: 'Enregistré',
        detail: `Livreur ${fuelDelivererDetail.name} enregistré`,
      });
    }).catch((error) => {
      processError(error, this);
    });
  }

  deleteFuelDeliverer(fuelDelivererDetail) {
    this.Api.fuelDeliverers.delete(fuelDelivererDetail.id)
      .then(() => {
        this.loadData()
        this.toast.show({
          severity: 'success',
          summary: 'Enregistré',
          detail: `Livreur ${fuelDelivererDetail.name} supprimé`,
        });
      })
      .catch((error) => {
        processError(error, this);
      });
  }

  ////////////////////////////////////////////////////////////////////////
  // FuelSellers

  showDialogFuelSeller(fuelSellerDetail) {
    this.setState({dialogFuelSellerVisible: true, fuelSellerDetail: fuelSellerDetail});
  }

  hideDialogFuelSeller() {
      this.setState({dialogFuelSellerVisible: false});
  }

  saveFuelSeller(fuelSellerDetail) {
    let promise;
    if (!fuelSellerDetail.id) {
      promise = this.Api.fuelSellers.create(fuelSellerDetail)
    } else {
      promise = this.Api.fuelSellers.update(fuelSellerDetail.id, fuelSellerDetail)
    }
    // either way, bail the dialog and refresh the page
    promise.then(() => {
      this.hideDialogFuelSeller();
      this.loadData();
      this.toast.show({
        severity: 'success',
        summary: 'Enregistré',
        detail: `Vendeur ${fuelSellerDetail.name} enregistré`,
      });
    }).catch((error) => {
      processError(error, this);
    });

  }

  deleteFuelSeller(fuelSellerDetail) {
    this.Api.fuelSellers.delete(fuelSellerDetail.id)
      .then(() => {
        this.loadData()
        this.toast.show({
          severity: 'success',
          summary: 'Enregistré',
          detail: `Vendeur ${fuelSellerDetail.name} supprimé`,
        });
      })
      .catch((error) => {
        processError(error, this);
      });
  }

  render() {

    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <DialogFuelDeliverer
          visible={this.state.dialogFuelDelivererVisible}
          detail={this.state.fuelDelivererDetail}
          onHide={this.hideDialogFuelDeliverer}
          saveFuelDeliverer={this.saveFuelDeliverer}
          key={v1()}
        />

        <DialogFuelSeller
          visible={this.state.dialogFuelSellerVisible}
          detail={this.state.fuelSellerDetail}
          onHide={this.hideDialogFuelSeller}
          saveFuelSeller={this.saveFuelSeller}
          key={v1()}
        />

        <div className='table-title'>
          <TabView>
            <TabPanel header="Livreurs">
              { this.renderDeliverers() }
            </TabPanel>
            <TabPanel header="Vendeurs">
            { this.renderSellers() }
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }

  renderDeliverers() {
    return (
      <div>
        <div className='table-title'>
          <label>Livreurs</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogFuelDeliverer()} icon="pi pi-plus" />
          </div>
        </div>
        <DataTable
          value={this.state.deliverers}
          sortField="name"
          sortOrder={1}
        >
          <Column field="name" header="Nom" sortable={true} filter={true} filterMatchMode="contains" />
          <Column field="num_deliveries" header="Nombre de livraisons" sortable={true} />

          <Column field="id" header="" className="tools-column" style={{width: '150px', textAlign: 'center'}} body={(rowdata) =>
            <div>
              <Button onClick={() => this.showDialogFuelDeliverer(rowdata)}
                type="button" icon="pi pi-pencil" tooltip="Modifier"
                className="p-button-default table-button">
              </Button>
              <Button
                onClick={() => this.deleteFuelDeliverer(rowdata)}
                disabled={rowdata.num_deliveries !== 0}
                type="button"
                icon="pi pi-trash"
                className="p-button-danger table-button">
              </Button>
            </div>
          }/>
        </DataTable>
      </div>
    )
  }


  renderSellers() {
    return (
      <div>
        <div className='table-title'>
          <label>Vendeurs</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogFuelSeller()} icon="pi pi-plus" />
          </div>
        </div>
        <DataTable
          value={this.state.sellers}
          sortField="name"
          sortOrder={1}
        >
          <Column field="name" header="Nom" sortable={true}  filter={true} filterMatchMode="contains" />
          <Column field="num_deliveries" header="Nombre de livraisons" sortable={true} />

          <Column field="id" header="" className="tools-column" style={{width: '150px', textAlign: 'center'}} body={(rowdata) =>
            <div>
              <Button onClick={() => this.showDialogFuelSeller(rowdata)}
                type="button" icon="pi pi-pencil" tooltip="Modifier"
                className="p-button-default table-button">
              </Button>
              <Button
                onClick={() => this.deleteFuelSeller(rowdata)}
                disabled={rowdata.num_deliveries !== 0}
                type="button"
                icon="pi pi-trash"
                className="p-button-danger table-button">
              </Button>
            </div>
          }/>
        </DataTable>
      </div>
    );
  }
}

export default FuelDeliverers
