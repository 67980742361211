import React from 'react';

import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FileUpload} from 'primereact/fileupload';

import Api from '../utils/Api';
import {processError} from '../utils/errors';


class MachineAttachmentsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {machine: this.props.machine, attachments: []};

    this.Api = new Api();

    this.fetchAttachments = this.fetchAttachments.bind(this);

    // load the attachments for the list
    this.fetchAttachments(this.props.machine)
  }

  /**
   * load the list of attachments for this machine
   */
  fetchAttachments () {
    if (!this.state.machine?.id) return

    this.Api.machineAttachments.list(this.state.machine.id)
      .then(res => {
        this.setState({attachments: res})
      })
      .catch((error) => {
        processError(error, this);
      });
  }

  /**
   * delete the given attachment
   */
  deleteAttachment (attachment) {
    this.Api.machineAttachments.delete(attachment.id).then(() => {
      this.fetchAttachments()
    })
    .catch((error) => {
      processError(error, this);
    });
  }

  downloadAttachment (attachment) {
    this.Api.machineAttachments.download(attachment.id);
  }

  render() {

    return (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <div className='table-title'>
          <label>Attachments</label>
          <div style={{marginLeft:'auto'}}>
            <FileUpload mode="basic" name="file"
                url={this.Api.machines.getAttachmentUploadUrl(this.state.machine.id)}
                onUpload={this.fetchAttachments}
                auto={true}
                chooseLabel="Upload.." />
          </div>
        </div>

        <DataTable value={this.state.attachments} responsive={true}>
          <Column field="filename" header="Filename" sortable={true} />
          <Column field="id" header="" className="tools-column"
                  style={{width: '150px', textAlign: 'center'}}
                  body={(rowdata) =>
                    <div>
                      <Button onClick={() => this.downloadAttachment(rowdata)}
                          type="button" icon="pi pi-download"
                          className="p-button-default table-button"></Button>
                      <Button onClick={() => this.deleteAttachment(rowdata)}
                          type="button" icon="pi pi-trash"
                          className="p-button-danger table-button"></Button>
                    </div>
                  }/>
        </DataTable>

      </div>
    );
  }
}

export default MachineAttachmentsList
