import React from 'react';

class Footer extends React.Component {

  render() {
    return (
        <div className="layout-footer p-grid p-justify-center p-align-center p-nogutter">
          <div className="p-col">
            &copy; TransEco2 2021
          </div>
        </div>
    )
  }
}

export default Footer;
