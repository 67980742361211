import React from 'react';

import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';

import Api from '../utils/Api';
import {toggleMenu} from '../utils/utils.js';

class SMS extends React.Component {

  constructor(props) {
    super(props);
    this.state = {sms: 'TB123456 DEBUT:30/04 12H55', message: ''};

    this.Api = new Api();
    this.simulateSMS = this.simulateSMS.bind(this);
  }

  componentDidMount() {
    toggleMenu(false)
  }

  simulateSMS() {
    this.Api.sms.send(this.state.sms).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        this.setState({message: 'message ok'})
      } else {
        response.text().then((text) => {
          this.setState({message: 'message bad: ' + text})
        })
      }
    })
  }

  render() {
    return (
      <div>

      <div className='table-title'>
        <label>Simulate SMS</label>
        <div style={{marginLeft:'50px'}}>
          <Button label="Send" onClick={() => this.simulateSMS()} icon="pi pi-envelope" />
        </div>
      </div>

        <InputTextarea
          rows={5} cols={50}
          value={this.state.sms}
          onChange={(e) => this.setState({sms: e.target.value})}
          autoResize={true}
        /><br/><br/>
        Example messages (replace times with correct times in UTC):
        <pre>{`MESSAGE_01 TB123456-XXXXXLTR-XXC-CUVE X-ADDITIF INJ XXLTR-ADDITIF REST XXXLTR
MESSAGE_02 TB123456 DEF QUALITE:XXXXX-XXXXXLTR-CUVE X
MESSAGE_03 TB123456 DEBUT:XX/XX XXHXX
MESSAGE_04 TB123456 FIN DE LIVRAISON:XX/XX XXHXX
MESSAGE_05 TB123456 BON QUALITE:XXXXXX-XXXXXXXLTR-CUVE X
MESSAGE_06 TB123456 STOP BRUTAL VOLUCOM
MESSAGE_07 TB123456 SOUCI DEB ADDITIF
MESSAGE_08 TB123456 OUVERTURE CAPOT
MESSAGE_09 TB123456 SERVICE EFFECTUEXX/XX XXHXX
MESSAGE_10 TB123456 NOUVEAU NIV ADDITIF XXXLTR
MESSAGE_11 TB123456 PIECE CHANGEE XXXXXXX
MESSAGE_12 TB123456 CUVE EVAC PLEINE
MESSAGE_13 TB123456 DEFAULT TEMPERATURE

for testing DeliveryTank (ensure date/time correct):
TB123456 DEBUT:03/06 20H00
TB123456-  100LTR-11C-CUVE 1-ADDITIF INJ 20.5LTR-ADDITIF REST 123.8LTR
TB123456 FIN DE LIVRAISON:03/06 21H00
      `}</pre>
        <span style={{color: 'red'}}>{this.state.message}</span>



      </div>
    );
  }
}

export default SMS
