import React from 'react';
import {v1} from  'uuid';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {Card} from 'primereact/card';
import {Dialog} from 'primereact/dialog';
import {ObjectUtils} from 'primereact/utils';

import LogitisticsUpdate from './LogitisticsUpdate';
import DialogDeliveryTank from './DialogDeliveryTank';

import Api from '../utils/Api';
import {isAdmin} from '../utils/utils.js';
import {processError} from '../utils/errors';


class DeliveryRowExpansion extends React.Component {

  constructor(props) {
    super(props);

    this.Api = new Api();

    this.state = {
      delivery: this.props.delivery,
      dialogEditDeliveryTankVisible: false,
      dialogDeleteDeliveryTankVisible: false
    }

    this.showDialogEditDeliveryTank = this.showDialogEditDeliveryTank.bind(this);
    this.hideDialogDeleteDeliveryTank = this.hideDialogDeleteDeliveryTank.bind(this);
    this.showDialogDeleteDeliveryTank = this.showDialogDeleteDeliveryTank.bind(this);
    this.saveDeliveryTank = this.saveDeliveryTank.bind(this);
  }

  /*
  Perform HTTP POST or PATCH to save the given DeliveryTank.
  The given instance should have a dict of the parent Delivery.id
  */
  saveDeliveryTank(deliveryTank) {
    let promise;
    if (!deliveryTank.id) {
      promise = this.Api.deliveryTanks.create(deliveryTank)
    } else {
      promise = this.Api.deliveryTanks.update(deliveryTank.id, deliveryTank)
    }
    // either way, bail the dialog and refresh the page
    promise.then((res) => {
      // post was success, update interface
      this.setState({dialogEditDeliveryTankVisible: false});
      let updating = true;
      this.props.updateDeliveryTankInState(this.state.delivery, res, updating);
      this.toast.show({
        severity: 'success',
        summary: 'Enregistré',
        detail: 'Livraison / cuve enregistré',
      });
  }).catch((error) => {
      processError(error, this);
    });
  }

  /*
  Perform HTTP POST or PATCH to save the given DeliveryTank.
  The given instance should have a dict of the parent Delivery.id
  */
  deleteDeliveryTank(delivery, deliveryTank) {

    // either way, bail the dialog and refresh the page
    this.Api.deliveryTanks.delete(deliveryTank.id).then((res) => {
        // post was success, update interface
        this.setState({dialogDeleteDeliveryTankVisible: false});
        let updating = false;
        this.props.updateDeliveryTankInState(delivery, deliveryTank, updating);
        this.toast.show({
          severity: 'success',
          summary: 'Supprimé',
          detail: 'Livraison / cuve supprimé',
        });

    }).catch((error) => {
      console.error(error);
      processError(error, this);
    });
  }


  hideDialogDeleteDeliveryTank() {
    this.setState({
      dialogDeleteDeliveryTankVisible: false
    });
  }

  showDialogDeleteDeliveryTank(delivery, deliveryTank) {
    this.setState({
      dialogDeleteDeliveryTankVisible: true,
      dialogDelivery: delivery,
      dialogDeliveryTank: deliveryTank || {id: null}
    });
  }

  showDialogEditDeliveryTank(delivery, deliveryTank) {
    this.setState({
      dialogEditDeliveryTankVisible: true,
      dialogDelivery: delivery,
      dialogDeliveryTank: deliveryTank || {id: null}
    });
  }

  /*
  Generic cell template that includes the responsive column heading for small screens
  */
  genericTemplate(rowData, column) {
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          <span className="p-column-data-resp right">
            {ObjectUtils.resolveFieldData(rowData, column.field)}
          </span>
      </React.Fragment>
    );
  }

  /*
  Render the deliveryTank acton buttons in the expansion table
  */
  deliveryTankButtonsTemplate(delivery, rowData, column) {
    return <div>
      <span className="p-column-title-resp">{column.header}</span>
      <Button
        type="button"
        icon="pi pi-pencil"
        className="space-right"
        onClick={() => this.showDialogEditDeliveryTank(delivery, rowData)}>
      </Button>
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        onClick={() => this.showDialogDeleteDeliveryTank(delivery, rowData)}>
      </Button>
    </div>
  }

  /*
  format the temperature cell for a delivery tank
  */
  temperatureTemplate(rowData, column) {
    let style = parseFloat(rowData.temperature) > 17 ? 'bold': 'normal'
    return <div>
      <span className="p-column-title-resp">{column.header}</span>
      <span className="p-column-data-resp right" style={{fontWeight: style}}
        >{rowData['temperature']}
      </span>
    </div>;
  }

  /*
  format the row for a delivery tank, based on volume
  */
  deliveryTankRowClassName(rowData) {
    let diffrence = rowData.volume_tank - rowData.volume;
    return {'p-highlight-pink' : (diffrence > 50)};
  }

  render() {

    let delivery = this.state.delivery;

    // tanks
    let tanks = [<span key={v1()} style={{padding: '1em'}}>(aucune)</span>];
    if (delivery?.delivery_tanks?.length > 0) {
      tanks =  (
        <div className="p-col-12" style={{padding: '0.5em'}}>
          <DataTable
            value={delivery.delivery_tanks}
            responsive={true}
            rowClassName={this.deliveryTankRowClassName}
            >

            <Column field="tank" header="Cuve" body={this.genericTemplate} />
            <Column field="volume" header="Volume livré (L)" body={this.genericTemplate} />
            <Column field="volume_at_15" header="Volume @15°C (L)" body={this.genericTemplate} />
            <Column field="volume_tank" header="Volume cuve (L)" body={this.genericTemplate} />
            <Column field="temperature" header="Température (°C)"  body={this.temperatureTemplate.bind(this)} />
            <Column field="volume_additive_1" header="Additif 1 (L)" body={this.genericTemplate} />
            <Column field="volume_additive_remaining_1" header="Additif 1 restant (L)" body={this.genericTemplate} />
            <Column field="volume_additive_2" header="Additif 2 (L)" body={this.genericTemplate} />
            <Column field="volume_additive_remaining_2" header="Additif 2 restant (L)" body={this.genericTemplate} />
            {isAdmin() &&
              <Column field="id" header="Actions" body={this.deliveryTankButtonsTemplate.bind(this, delivery)} style={{textAlign:'center'}}/>
            }
          </DataTable>
        </div>
      )
    }

    // incidents
    let incidents = [<span style={{padding: '1em'}} key={Math.random()}>(aucune)</span>];
    if (delivery?.delivery_incidents?.length > 0) {
      incidents = delivery.delivery_incidents.map((incident, index) => (
        <div className="p-sm-12 p-md-6 p-lg-4" style={{padding: '0.5em'}} key={index}>
          <Card>
              <div className="p-grid">
                  <div className="p-col-4">Cuve: </div>
                  <div className="p-col-8" style={{fontWeight: 'bold'}}>{incident.tank}</div>

                  <div className="p-col-4">Volume: </div>
                  <div className="p-col-8" style={{fontWeight: 'bold'}}>
                    <span style={{display: incident.volume_end ? 'block': 'none'}}>
                      {incident.volume_start} - {incident.volume_end} ({incident.volume_end - incident.volume_start}) L
                    </span>
                  </div>

                  <div className="p-col-4">Message: </div>
                  <div className="p-col-8" style={{fontWeight:'bold'}}>{incident.text}</div>
              </div>
          </Card>
        </div>
      ))
    }

    return  (

      <div>

        <Toast ref={(el) => this.toast = el} />

        <DialogDeliveryTank
          visible={this.state.dialogEditDeliveryTankVisible}
          delivery={this.state.dialogDelivery}
          deliveryTank={this.state.dialogDeliveryTank}
          onHide={() => this.setState({dialogEditDeliveryTankVisible: false})}
          saveDeliveryTank={this.saveDeliveryTank}
          key={v1()}
        />

        <Dialog
          header="Supprimer cuve"
          visible={this.state.dialogDeleteDeliveryTankVisible}
          // style={{ width: '50vw' }}
          // footer={this.renderFooter('displayBasic')}
          onHide={() => {}}
          footer={<div>
            <Button label="Supprimer"
              icon="pi pi-trash"
              onClick={() => this.deleteDeliveryTank(this.state.delivery, this.state.dialogDeliveryTank)}/>
            <Button label="Cancel"
              icon="pi pi-times"
              onClick={this.hideDialogDeleteDeliveryTank}
              className="p-button-secondary" />
          </div>}
          >
            Est-ce que vous êtes sur de supprimer l'enregistrement pour cuve: {this.state.dialogDeliveryTank?.tank} ?
        </Dialog>



        <div style={{color: '#888888'}}>
          Livraison: <b>{delivery.nth_in_month}</b> / {delivery.company.deliveries_per_month} du mois
        </div>

        <div className='table-title'>
          <label>Cuves</label>
          <div style={{marginLeft:'auto'}}>
            {isAdmin() &&
              <Button label="Ajouter cuve" onClick={() => this.showDialogEditDeliveryTank(delivery)} icon="pi pi-plus" />
            }
          </div>
        </div>
        <div className="p-grid p-fluid">
          {tanks}
        </div>

        <div className='table-title'>
          <label>Incidents</label>
        </div>
        <div className="p-grid p-fluid">
          {incidents}
        </div>

        <LogitisticsUpdate
          delivery={delivery}
          updateDeliveryInState={this.props.updateDeliveryInState}
          />

      </div>
    );
  }

}

export default DeliveryRowExpansion;
