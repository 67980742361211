/**
 * general utilities
 */

 export const getToken = function() {
   if (localStorage.getItem('user')) {
     return JSON.parse(localStorage.getItem('user')).token;
   }
   return '';
 }

 export const isAdmin = function() {
   if (localStorage.getItem('user')) {
     return JSON.parse(localStorage.getItem('user')).is_admin;
   }
   return false;
 }

 export const getUser = function() {
  if (localStorage.getItem('user')) {
    return JSON.parse(localStorage.getItem('user'));
  }
  return null;
 }

 export const toggleMenu = function(state) {
   /* toggle the hamburger menu open / closed */
   var x = document.getElementById("topNav");

   if (state === false) {
     x.className = "topnav";
   } else if (x.className === "topnav") {
     x.className += " responsive";
   } else {
     x.className = "topnav";
   }
 }
