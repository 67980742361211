import React from 'react';

import logo from '../static/logo.png';
import Api from '../utils/Api';
import {toggleMenu} from '../utils/utils.js';


class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.Api = new Api();
    this.handleLogout = this.handleLogout.bind(this);
  }


  handleLogout(event) {
    this.Api.auth.logout()
      .then((res) => {
        this.props.onLogout();
      },function(error){
        console.log(error)
      });
  }



  render() {

    // little subcomponent for the nav menu items
    const NavMenu = (props) => {
      if (props.condition === true) {
        return (
          <a href={props.href} onClick={props.onClick} style={{cursor:'pointer'}}> {props.title} </a>
        )
      } else {
        return null;
      }
    };

    return (
      <div className="layout-topbar p-grid p-nogutter">
        <div className="p-col-fixed" >
          <a href='/#/'><img className='logo' alt='' src={logo} /></a>
        </div>
        <div className="p-col topbar-menu">

          <div className='topnav' id='topNav'>
            <NavMenu onClick={this.handleLogout} title='Logout' condition={this.props.parentState.loggedIn} />
            <NavMenu href='#/login' title='Login' condition={!this.props.parentState.loggedIn} />
            <NavMenu href='#/sigfox-log' title='SigFox Log' condition={this.props.parentState.isAdmin} />
            <NavMenu href='#/sms-log' title='SMS Log' condition={this.props.parentState.isAdmin} />
            {/* <NavMenu href='#/sms' title='SMS' condition={this.props.parentState.isAdmin} /> */}
            <NavMenu href='#/deliverers' title='Livreurs' condition={this.props.parentState.isAdmin} /> />
            <NavMenu href='#/services' title='Maintenance' condition={this.props.parentState.isAdmin} />
            <NavMenu href='#/companies' title='Entreprises' condition={this.props.parentState.isAdmin} />
            <NavMenu href='#/page1' title='Livraisons' condition={this.props.parentState.loggedIn} />
            <a className="icon" onClick={toggleMenu}>
              <i className="pi pi-bars"></i>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default NavBar;
