/*
Display an error toast, with some formatting logic for known
DRF error types
*/
export const processError = (error, context) => {

  // first determine if our error is a dict, in which case we return a resolved promise
  let promise = null;
  if (error.constructor === Object) {
    promise = Promise.resolve(error);
  } else {
    // the other scenario is that this is a un parsed json response, in which case
    // create a promise for it to be parsed
    let responseIsJson = (
      error.response &&
      error.response.status === 400 &&
      error.response.headers.get("content-type") &&
      error.response.headers.get("content-type").indexOf("application/json") !== -1
    )
    if (responseIsJson) {
      promise = error.response.json();
    }
  }

  // if we're parsing json, present the error in a toast
  if (promise) {
    promise.then((errors) => {
      let message = '';

      // if the error is a dict with a 'detail' prop, then use it as our message
      if (errors.detail) {
        message = errors.detail;
      } else {
        // otherwise assume its a list of field: value ValueErrors
        let messages = []
        for (const key in errors) {
          messages.push(key + ': ' + errors[key].join(' - '));
        }
        message = messages.join('\n')
      }

      context.toast.show({
        severity: 'error',
        summary: 'Erreur',
        detail: message,
        life: 5000,
        style: {'white-space': 'pre-wrap'}
      });

    });
    console.error(error);

  } else {
    // otherwise best guess error message from status text
    context.toast.show({severity: 'error', summary: 'Erreur', detail: error.statusText});
    console.error(error);
  }
}
