import React from 'react';
import Moment from 'react-moment';
import _ from 'lodash';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {Dropdown} from 'primereact/dropdown';
import {Dialog} from 'primereact/dialog';
import {ObjectUtils} from 'primereact/utils';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu, isAdmin, getUser} from '../utils/utils.js';
import DeliveryRowExpansion from './DeliveryRowExpansion';
import DialogDelivery from './DialogDelivery';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      deliveries: [],
      companyName: null,
      companyNames: [],
      dialogDeleteDeliveryVisible: false,
      loading: false,
      lazyParams: {
        first: 0,
        rows: 50,
        page: 0,
        sortField: 'date_start',
        sortOrder: -1,
        filters: {}
      }
    };
    this.Api = new Api();

    this.refreshList = this.refreshList.bind(this);
    this.companyFilter = this.companyFilter.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.updateDeliveryTankInState = this.updateDeliveryTankInState.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.saveDelivery = this.saveDelivery.bind(this);
    this.updateDeliveryInState = this.updateDeliveryInState.bind(this);
    this.hideDialogDeleteDelivery = this.hideDialogDeleteDelivery.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onPage = this.onPage.bind(this);
  }

  componentDidMount() {
      toggleMenu(false)
      this.refreshList()
      this.loadCompanyFilterValues()
  }

  showDialogDeleteDelivery(delivery) {
    this.setState({
      dialogDeleteDeliveryVisible: true,
      dialogDelivery: delivery
    });
  }

  hideDialogDeleteDelivery() {
    this.setState({
      dialogDeleteDeliveryVisible: false
    });
  }

  deleteDelivery(delivery) {
    this.hideDialogDeleteDelivery();
    if (!delivery) return;
    this.Api.deliveries.delete(delivery.id).then(() => this.refreshList())
  }

  refreshList() {
    this.setState({loading: true});

    const djangoOptions = this.buildDjangoOptions(this.state.lazyParams)

    this.Api.deliveries.list(djangoOptions).then((res) => {
      this.setState({ deliveries: res.results, totalRecords: res.count});
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  onPage(event) {
    const lazyParams = Object.assign({}, this.state.lazyParams, event)
    this.setState({ lazyParams: lazyParams}, this.refreshList);
  }

  onSort(event) {
    const lazyParams = Object.assign({}, this.state.lazyParams, event)
    // reset the pagination when we change the sort
    lazyParams.first = 0
    lazyParams.page = 0
    this.setState({ lazyParams: lazyParams}, this.refreshList);
  }

  onFilter(event) {
    const lazyParams = Object.assign({}, this.state.lazyParams, event)
    this.setState({ lazyParams: lazyParams}, this.refreshList);
  }

  /**
   * Transfor prime react lazy event into url params suitable for django.
   *
   * @param {*} lazyEvent : the primeReact lazyEvent which includes all acted sort / page / filter
   *     parameters
   * @returns : doct of options to by applied to django filters via the http queryparams
   */
  buildDjangoOptions (lazyEvent) {
    let djangoOptions = {
      limit: lazyEvent.rows,
      offset: (lazyEvent.rows * lazyEvent.page)
    }
    if (lazyEvent.sortField) {
      if (lazyEvent.sortOrder === -1) {
        djangoOptions.ordering = `-${lazyEvent.sortField}`
      } else {
        djangoOptions.ordering = lazyEvent.sortField
      }
    }
    for (const key in lazyEvent.filters) {
      const fieldFilter = lazyEvent.filters[key]
      if (fieldFilter.value !== '') {
        djangoOptions[key] = fieldFilter.value
      }
    }


    return djangoOptions
  }

  deliveryRowClassName(rowData) {
    let incidents = rowData.delivery_incidents;
    return {'p-highlight-pink' : (incidents?.length > 0)};
  }

  /*
  format incoming ISO/UTC date to human-friendly
  */
  dateTemplate(rowData, column) {
    if (rowData[column.field] == null) return ''
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          <Moment format="DD/MM/YYYY HH:mm">{rowData[column.field]}</Moment>
      </React.Fragment>
    );
  }

  /*
  format numbers with commas separating thousands and no decimal places
  */
  numberTemplate(rowData, column) {
    var value = ''
    if (rowData[column.field]) {
      value = rowData[column.field].toLocaleString(undefined, {maximumFractionDigits:0});
    }
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          { value }
      </React.Fragment>
    );
  }

  /*
  Generic cell template that includes the responsive column heading for small screens
  */
  genericTemplate(rowData, column) {
    return (
      <React.Fragment>
          <span className="p-column-title-resp">{column.header}</span>
          {ObjectUtils.resolveFieldData(rowData, column.fieldData||column.field)}
      </React.Fragment>
    );
  }

  actionsTemplate(rowData, column) {
    return  <div>
      <span className="p-column-title-resp">{column.header}</span>
      {rowData.date_end &&
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={() => this.Api.deliveries.pdf(rowData.id)}
          className="space-right">
        </Button>
      }
      {isAdmin() &&
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => this.showDialogDeleteDelivery(rowData)}>
        </Button>
      }
    </div>
  }

  loadCompanyFilterValues() {
    // load the list of company names from the api
    this.Api.companies.get({ordering: 'name'}).then((companies) => {
      let companyDicts = []
      for (var index in companies) {
        companyDicts.push({
          label: companies[index].name,
          value: companies[index].name,
          key: index
        })
      }
      // add the clear filter item to the start of the list
      companyDicts.unshift({label: 'Toutes', value: null})
      // supply list of possible filters
      this.setState({companyNames: companyDicts});
    }).catch((error) => {
      processError(error, this);
    });
  }

  /*
  filter the deliveries table by company name
  */
  onCompanyNameChange(event) {
      this.mainTable.filter(event.value, 'machine__site__company__name', 'equals');
      this.setState({companyName: event.value});
  }

  /*
  present the menu for the company dropdown
  */
  companyFilter() {
    return <Dropdown
             style={{minWidth: 'auto', width: '100%'}}
             value={this.state.companyName}
             options={this.state.companyNames}
             filter={true} showFilterClear={true} showClear={true}
             onChange={this.onCompanyNameChange}
           />
  }

  /*
  update the local state for a single incoming delivery, as defined by its id
  */
  updateDeliveryInState(delivery) {
    // clone deliveries state
    let deliveries = _.clone(this.state.deliveries);
    // determine the index of the delivery to change
    var targetIndex = _.findIndex(deliveries, function(candidateDelivery) {
       return delivery.id === candidateDelivery.id;
    });
    // update the Delivery to be changed
    deliveries[targetIndex] = delivery
    // update component state
    this.setState({
      deliveries: deliveries
    })
  }

  /*
  Refresh or remove a DeliveryTank in the UI current UI state.

  Called from the DeliveryRowExpansion child components.

  Can be called with isUpdate true or false to determine if the given deliveryTank
  should be spliced back into its delivery after having been removed.
  */
  updateDeliveryTankInState(delivery, deliveryTank, isUpdate) {
    // clone deliveries state
    const deliveries = _.clone(this.state.deliveries);
    // determine the index of the delivery to change
    var targetIndex = _.findIndex(deliveries, function(candidateDelivery) {
       return delivery.id === candidateDelivery.id;
    });
    // extract the Delivery to be changed and insert the new DeliveryTank
    var targetDelivery = deliveries[targetIndex];

    // remove old deliveryTank (if there was one)
    var deleteIndex = _.findIndex(
      targetDelivery.delivery_tanks, (dt) => {
          return deliveryTank.id === dt.id
      }
    );
    // found existing id, remove it
    if (deleteIndex > -1) {
      targetDelivery.delivery_tanks.splice(deleteIndex, 1);
    }

    // determine if we are replacing the removed DeliveryTank with the new one
    if (isUpdate) {
      // find the index into which it should be inserted to maintain the correct
      // sort by `tank`
      var insertIndex = _.sortedIndexBy(targetDelivery.delivery_tanks, deliveryTank, (dt) => { return dt.tank });
      // insert into the correct index
      delete deliveryTank.delivery;
      targetDelivery.delivery_tanks.splice(insertIndex, 0, deliveryTank);
    }

    // update component state
    this.setState({
      deliveries: deliveries
    })
  }

  /*
  provide the sub-component to display DeliveryDetail, including DeliveryTanks list
  */
  rowExpansionTemplate(delivery) {
    return <DeliveryRowExpansion
             updateDeliveryTankInState={this.updateDeliveryTankInState}
             updateDeliveryInState={this.updateDeliveryInState}
             delivery={delivery}
           />
  }

  showDialogAddDelivery() {
      this.setState({dialogEditDeliveryVisible: true});
  }

  saveDelivery(dialogState) {
    let newDelivery = {
      date_start: dialogState.date_start,
      date_end: dialogState.date_end,
      machine: {id: dialogState.machine}
    }
    this.Api.deliveries.create(newDelivery).then((res) => {
      this.refreshList();
      this.setState({dialogEditDeliveryVisible: false});
      this.toast.show({
        severity: 'success',
        summary: 'Enregistré',
        detail: 'Livraison enregistré',
      });
    }).catch((error) => {
      processError(error, this);
    });
  }

  render() {
    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <DialogDelivery
          visible={this.state.dialogEditDeliveryVisible}
          onHide={() => this.setState({dialogEditDeliveryVisible: false})}
          saveDelivery={this.saveDelivery}
          style={{ width: '90vw' }}
        />

        <Dialog
          header="Supprimer livraison"
          visible={this.state.dialogDeleteDeliveryVisible}
          onHide={() => {}}
          footer={<div>
            <Button label="Supprimer"
              icon="pi pi-trash"
              onClick={() => this.deleteDelivery(this.state.dialogDelivery)}/>
            <Button label="Cancel"
              icon="pi pi-times"
              onClick={this.hideDialogDeleteDelivery}
              className="p-button-secondary" />
          </div>}
          >
          Est-ce que vous êtes sur de supprimer le livraison pour machine:
            {' ' + this.state.dialogDelivery?.machine?.number}, livré le:
            <Moment format=" DD/MM/YYYY">
              {this.state.dialogDelivery?.date_start}
            </Moment> ?
        </Dialog>

        <div className='table-title'>
          <label className='space-right'>Livraisons</label>
          <div style={{marginLeft:'auto'}}>
            <Button
              label="Exporter"
              onClick={() => this.Api.deliveries.xlsx()}
              icon="pi pi-file-excel"
              className="space-right"
            />
            {!isAdmin() &&
              <Button
                label="Statistiques"
                onClick={() => this.Api.companies.pdf(getUser().company_id)}
                icon="pi pi-file-pdf"
                className="space-right"
              />
            }
            {isAdmin() &&
              <Button
                label="Ajouter livraison"
                onClick={() => this.showDialogAddDelivery()}
                icon="pi pi-plus"
              />
            }
          </div>
        </div>
        <DataTable
          paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
          currentPageReportTemplate='Items {first} - {last} de {totalRecords}'
          rows={this.state.lazyParams.rows}
          first={this.state.lazyParams.first}
          rowsPerPageOptions={[50,200,500]}
          paginator
          lazy
          totalRecords={this.state.totalRecords}
          // sortField='date_start'
          // sortOrder={-1}
          onPage={this.onPage}
          onSort={this.onSort}
          sortField={this.state.lazyParams.sortField}
          sortOrder={this.state.lazyParams.sortOrder}
          onFilter={this.onFilter}
          filters={this.state.lazyParams.filters}
          loading={this.state.loading}

          value={this.state.deliveries}
          dataKey='id'
          ref={(el) => this.mainTable = el}

          expandedRows={this.state.expandedRows}
          rowExpansionTemplate={this.rowExpansionTemplate}
          onRowToggle={(e) => this.setState({expandedRows:e.data})}

          rowClassName={this.deliveryRowClassName}
          tableClassName='delivery-table'
        >

          <Column className='expander' style={{width: '4em'}} expander={true} />
          <Column field="machine__site__company__name" fieldData="company.name" header="Company" sortable={true} filter={true}  filterMatchMode="contains"
            filterElement={this.companyFilter()} body={this.genericTemplate} />
          <Column field="machine__site__name" fieldData="machine.site_name" header="Site" sortable={true} filter={true} filterMatchMode="contains"
            body={this.genericTemplate} />
          <Column field="machine__number" fieldData="machine.number" header="Machine" sortable={true} filter={true} filterMatchMode="contains"
            body={this.genericTemplate} />
          <Column field="date_start" header="Date debut" sortable={true} body={this.dateTemplate.bind(this)} />
          <Column field="date_end" header="Date fin" sortable={true} body={this.dateTemplate.bind(this)} />
          <Column field="volume_qs" header="Volume livré (L)" sortable={true} body={this.numberTemplate.bind(this)} />
          <Column field="volume_tank_qs" header="Volume cuves (L)" sortable={true} body={this.numberTemplate.bind(this)} />
          <Column field="id" header="Action" body={this.actionsTemplate.bind(this)} />
        </DataTable>
      </div>
    )
  }
}

export default Home;
