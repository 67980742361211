import React from 'react';
import _ from 'lodash';

import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

import Api from '../utils/Api';

class DialogDelivery extends React.Component {

  constructor(props) {
    super(props);

    this.Api = new Api();

    var defaultModel = {
      date_start: '',
      date_end: '',
      machine: null,
      machinesList: [],
      machinesListFetched: false
    }
    this.state = Object.assign({}, defaultModel, props.detail);

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.renderEditField = this.renderEditField.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  /*
  Load the list of machines for the dropdown.
  We make an effort here to avoid loading the list until it is really necessary.
  */
  componentDidUpdate() {
    // don't load if we're not visible
    if (!this.props.visible) {
      return;
    }
    // do not load if they're already loaded
    if (this.state.machinesListFetched) {
      return;
    }
    // ok, let's load
    this.Api.machines.list().then((res) => {
      var options = []
      _.each(res, (machine) => {
        options.push({label: machine.number, value: machine.id})
      });
      this.setState({machinesListFetched: true, machinesList: options})
    })
  }

  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value || '' } );
  }

  renderEditField(field, title, keyfilter) {
    return (
      <span className="p-float-label dialog-field">
          <InputText name={field} value={this.state[field]}
           onChange={this.inputChangeHandler}
           keyfilter={keyfilter} style={{width: '250px'}}
           />
          <label htmlFor={field}>{title}</label>
      </span>
    )
  }

  onShow() {
    this.setState({
      date_start: '',
      date_end: '',
      machine: null,
    });
  }

  render() {
    // ensure required fields
    const { date_start, date_end, machine } = this.state;
    const isValid = date_start && date_end && machine ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.saveDelivery(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Livraison"
          onHide={this.props.onHide}
          visible={this.props.visible}
          footer={footer}
          onShow={this.onShow}
          blockScroll
          >

          <div>
            Nouveau livraison
          </div>

          <span className="p-float-label dialog-field">
            <Dropdown name="machine"
              value={this.state.machine}
              options={this.state.machinesList}
              onChange={this.inputChangeHandler}
              appendTo={document.body}
              style={{width: '250px'}}
              filter
              placeholder="Machine *"/>
          </span>

          <span className="p-float-label dialog-field">
            <Calendar
              name='date_start'
              value={this.state.date_start}
              onChange={this.inputChangeHandler}
              style={{width: '250px'}}
              showIcon
              appendTo={document.body}
              touchUI
              showSeconds={false}
              required={true}
              showTime hourFormat="24"
              dateFormat="dd/mm/yy"
              placeholder="Date debut *"/>
          </span>

          <span className="p-float-label dialog-field">
            <Calendar
              name='date_end'
              value={this.state.date_end}
              onChange={this.inputChangeHandler}
              style={{width: '250px'}}
              showIcon
              appendTo={document.body}
              touchUI
              showSeconds={false}
              required={true}
              showTime hourFormat="24"
              dateFormat="dd/mm/yy"
              placeholder="Date fin *"/>
          </span>
        </Dialog>

      </div>
    );
  }
}

export default DialogDelivery
