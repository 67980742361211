import React from 'react';

import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';


import NavBar from './NavBar';
import Home from './Home';
import Companies from './Companies';
import Services from './Services';
import FuelDeliverers from './FuelDeliverers';
import CompanyDetail from './CompanyDetail';
import Login from './Login';
import SMS from './SMS';
import SMSLog from './SMSLog';
import SigFoxLog from './SigFoxLog';
import {isAdmin} from '../utils/utils.js'

class MainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {loggedIn: this.isLoggedIn(), isAdmin: isAdmin()}
    this.onLogin = this.onLogin.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  isLoggedIn() {
    return localStorage.getItem('user') != null;
  }

  // called by Login following a successfull login
  onLogin(userDetail) {
    this.setState({loggedIn: true, isAdmin: userDetail.is_admin});
    localStorage.setItem('user', JSON.stringify(userDetail));
    window.location.href = "/#/";
  }

  // called by NavBar following successfull logout
  onLogout(detail) {
    localStorage.removeItem('user');
    this.setState({loggedIn: false, isAdmin: false});
    window.location.href = "/#/";
  }

  render() {
    return (
      <div>
        <NavBar parentState={this.state} onLogout={this.onLogout}/>
        <div className="mainpage-wrapper">
          <div>
            <div className="p-grid p-nogutter">
              <div className="p-col-0 p-sm-0 p-md-1 p-lg-1"></div>
                <div className="p-col-12 p-sm-12 p-md-10 p-lg-10">
                  <div className="p-component">
                <HashRouter>
                  <Switch>
                    <Route exact path="/home" render={() => <Home parentState={this.state} />} />
                    <Route exact path="/companies/" component={Companies} />
                    <Route exact path="/company/:id/" component={CompanyDetail} />
                    <Route exact path="/services/" component={Services} />
                    <Route exact path="/deliverers/" component={FuelDeliverers} />

                    <Route exact path="/login" render={() => <Login onLogin={this.onLogin} />} />
                    <Route exact path="/sms" component={SMS} />
                    <Route exact path="/sms-log" component={SMSLog} />
                    <Route exact path="/sigfox-log" component={SigFoxLog} />
                    <Redirect from="*" to="/home" />
                  </Switch>
                </HashRouter>
              </div>
              </div>

            </div>
          </div>
        </div>
                <br/><br/>
      </div>
    );
  }
}

export default MainPage;
