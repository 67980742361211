import React from 'react';
import Moment from 'react-moment';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';

class SMSLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.Api = new Api();
  }

  componentDidMount() {
    toggleMenu(false);
    this.refreshList()
  }

  refreshList() {
    this.setState({loading: true});

    this.Api.sms.get().then((data) => {
      this.setState({smses: data})
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  rowClassName(rowData) {
      return {'p-highlight-pink' : rowData.error};
  }

  /*
  format incoming ISO/UTC date to human-friendly
  */
  dateTemplate(rowData, column) {
    if (rowData[column.field] == null) return ''
    return <Moment format="DD/MM/YYYY HH:mm:ss">{rowData[column.field]}</Moment>
  }

  render() {
    return (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <div className='table-title'>
          <label>SMS Log</label>
        </div>

        <DataTable
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Items {first} - {last} de {totalRecords}" rows={100}
          rowsPerPageOptions={[100,200,500]}
          paginator
          sortField="date"
          sortOrder={-1}
          loading={this.state.loading}

          value={this.state.smses}
          responsive={true}
          rowClassName={this.rowClassName
        }>
          <Column field="date" header="Date" sortable={true} body={this.dateTemplate.bind(this)}/>
          <Column field="num_from" header="Envoyé par" filter={true} filterMatchMode="contains" />
          <Column field="body" header="Texte"  filter={true} filterMatchMode="contains"/>
          <Column field="error" header="Erreur" />
        </DataTable>
      </div>
    )
  }
}

export default SMSLog;
