import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';

import MachineAttachmentsList from './MachineAttachmentsList';

class DialogMachine extends React.Component {

  constructor(props) {
    super(props);
    var defaultModel = {
      id: null,
      number: '',
      site: '',
      combined_tank_correction: '',
      oversize_tank_correction: '',
      volume_additive_annual_1: '',
      volume_additive_annual_2: '',
      volume_additive_remaining_1: '',
      volume_additive_remaining_2: '',
      pulses_per_litre_volume: '',
      pulses_per_litre_additive1: '',
      pulses_per_litre_additive2: '',
      disable_alerts: false,
      hose_expiry: ''
    }
    this.state = Object.assign({}, defaultModel, props.detail);

    // build a list of label / values for the list of site dicts given
    // in the props
    this.sitesList = props.sites.map((site) => (
      {label: site.name, value: site.id}
    ));

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.renderEditField = this.renderEditField.bind(this);

  }

  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value || null } );
  }

  renderEditField(field, title, keyfilter) {
    return (
      <span className="p-float-label dialog-field">
          <InputText name={field} value={this.state[field]|| ''}
           onChange={this.inputChangeHandler} keyfilter={keyfilter} style={{width: '350px'}}/>
          <label htmlFor={field}>{title}</label>
      </span>
    )
  }

  render() {
    // ensure required fields
    const { site, number } = this.state;
    const isValid = site && number.length > 0 ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.saveMachine(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Machine"
          onHide={this.props.onHide}
          visible={this.props.visible}
          className={"dialog"}
          footer={footer}>

          <Dropdown name="site"
            value={this.state.site}
            options={this.sitesList}
            onChange={this.inputChangeHandler}
            placeholder="Site *"/>

            {this.renderEditField('number', 'Numero *')}

            <span className="p-float-label dialog-field">
                <InputTextarea name='address' value={this.state['address']|| ''}
                  onChange={this.inputChangeHandler} style={{width: '350px', height: '100px'}}/>
                <label htmlFor='address'>Adresse</label>
            </span>

            {this.renderEditField('post_code', 'Code postal', 'text')}
            {this.renderEditField('city', 'Ville', 'text')}

            {this.renderEditField('combined_tank_correction', 'Coef. de correction pour cuve > 10.2K', 'pnum')}
            {this.renderEditField('oversize_tank_correction', 'Coef. de correction pour cuve > 60L que prevue', 'pnum')}

            {this.renderEditField('volume_additive_annual_1', 'Volume additif 1 annuel', 'pnum')}
            {this.renderEditField('volume_additive_annual_2', 'Volume additif 2 annuel', 'pnum')}

            {this.renderEditField('volume_additive_remaining_1', 'Volume additif 1 remaining', 'pnum')}
            {this.renderEditField('volume_additive_remaining_2', 'Volume additif 2 remaining', 'pnum')}

            {this.renderEditField('volume_additive_theoretic_1', 'Volume additif 1 théorétique', 'pnum')}
            {this.renderEditField('volume_additive_theoretic_2', 'Volume additif 2 théorétique', 'pnum')}

            {this.renderEditField('pulses_per_litre_volume', 'Pulses par litre pour machines v2', 'pnum')}
            {this.renderEditField('pulses_per_litre_additive1', 'Pulses par litre additif 1 pour machines v2', 'pnum')}
            {this.renderEditField('pulses_per_litre_additive2', 'Pulses par litre additif 2 pour machines v2', 'pnum')}

            <span className="p-float-label dialog-field">
              <Calendar
                name='hose_expiry'
                value={this.state.hose_expiry}
                onChange={this.inputChangeHandler}
                style={{width: '250px'}}
                showIcon
                appendTo={document.body}
                touchUI
                required={false}
                dateFormat="dd-mm-yy"
                dataType='string'
                showButtonBar
                placeholder=""/>
                <label htmlFor="hose_expiry">Validité flexible</label>
            </span>

            <div className="p-field-checkbox" style={{'marginTop': '20px'}}>
              <Checkbox
                inputId="disable_alerts"
                checked={this.state['disable_alerts']}
                onChange={e => this.setState({ disable_alerts: e.checked })}
                className="space-right"
              />
              <label htmlFor="disable_alerts">Disable alerts</label>
            </div>

            <MachineAttachmentsList machine={this.state} ></MachineAttachmentsList>

        </Dialog>

      </div>
    );
  }
}

export default DialogMachine
