import React from 'react';

import moment from 'moment'

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toast} from 'primereact/toast';

import {v1} from  'uuid';

import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';


import DialogCompany from './DialogCompany';
import DialogUser from './DialogUser';
import DialogSite from './DialogSite';
import DialogMachine from './DialogMachine'
import DialogPassword from './DialogPassword';

class CompanyDetail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      detail: {
        id: props.match.params.id,
        name: '',
        users: [],
        sites: [],
        machines: []
      },
      logoUrl: null,

      userDetail: {id: null},
      dialogUserVisible: false,
      dialogPasswordVisible: false,

      siteDetail: {id: null},
      dialogSiteVisible: false,

      machineDetail: {id: null},
      dialogMachineVisible: false,
    };

    this.Api = new Api();

    this.onUploadLogo = this.onUploadLogo.bind(this);

    this.saveCompany = this.saveCompany.bind(this);
    this.showDialogCompany = this.showDialogCompany.bind(this);
    this.hideDialogCompany = this.hideDialogCompany.bind(this);

    this.saveUser = this.saveUser.bind(this);
    this.showDialogUser = this.showDialogUser.bind(this);
    this.hideDialogUser = this.hideDialogUser.bind(this);
    this.savePassword = this.savePassword.bind(this);
    this.showDialogPassword = this.showDialogPassword.bind(this);
    this.hideDialogPassword = this.hideDialogPassword.bind(this);

    this.saveSite = this.saveSite.bind(this);
    this.showDialogSite = this.showDialogSite.bind(this);
    this.hideDialogSite = this.hideDialogSite.bind(this);

    this.saveMachine = this.saveMachine.bind(this);
    this.showDialogMachine = this.showDialogMachine.bind(this);
    this.hideDialogMachine = this.hideDialogMachine.bind(this);

    // this.processError = processError.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData()
    toggleMenu(false)
  }

  loadData() {
    this.Api.companies.retrieve(this.state.detail.id).then((data) => {
      // build a flat list of machines in the state.machines for
      // convenience in the Companies page
      data.machines = []
      data.sites.map((site, key) =>
        site.machines.map((machine, key) => {
          machine['site__name']  = site.name
          return data.machines.push(machine)
        })
      );
      this.setState({
        detail: data,
        logoUrl: this.Api.companies.getLogoUrl(data.id) + '&' + Math.random()
      })
    }).catch((error) => {
      processError(error, this);
    });
  }

  onUploadLogo(event) {
      this.toast.show({severity: 'info', summary: 'Success', detail: 'Logo mise à jour'});
      this.loadData()
  }

  ////////////////////////////////////////////////////////////////////////
  // Company

  showDialogCompany() {
    this.setState({dialogCompanyVisible: true});
  }

  hideDialogCompany(event) {
      this.setState({dialogCompanyVisible: false});
  }

  saveCompany(companyDetail) {
    var promise = this.Api.companies.update(companyDetail.id, companyDetail)
    promise.then(() => {
      this.hideDialogCompany();
      this.loadData();
    }).catch((error) => {
      processError(error, this);
    });
  }


  ////////////////////////////////////////////////////////////////////////
  // Users

  showDialogUser(userDetail) {
    this.setState({dialogUserVisible: true, userDetail: userDetail || {id: null}});
  }

  hideDialogUser(event) {
      this.setState({dialogUserVisible: false});
  }

  saveUser(userDetail) {
    userDetail.company = this.state.detail.id
    var promise;
    if (!userDetail.id) {
      promise = this.Api.users.create(userDetail)
    } else {
      promise = this.Api.users.update(userDetail.id, userDetail)
    }
    // either way, bail the dialog and refresh the page
    promise.then(() => {
      this.hideDialogUser();
      this.loadData();
    }).catch((error) => {
      processError(error, this);
    });

  }

  deleteUser(detail) {
    this.Api.users.delete(detail.id)
      .then(() => this.loadData())
      .catch((error) => {
        processError(error, this);
      });
  }


  showDialogPassword(passwordDetail) {
    this.setState({dialogPasswordVisible: true, passwordDetail: passwordDetail});
  }

  hideDialogPassword(event) {
      this.setState({dialogPasswordVisible: false});
  }

  savePassword(passwordDetail) {
    var promise = this.Api.users.updatePassword(passwordDetail.id, passwordDetail)

    promise.then(() => {
      this.hideDialogPassword();
      this.loadData();
    }).catch((error) => {
      processError(error, this);
    });

  }



  ////////////////////////////////////////////////////////////////////////
  // Sites

  showDialogSite(siteDetail) {
    this.setState({dialogSiteVisible: true, siteDetail: siteDetail || {id: null}});
  }

  hideDialogSite(event) {
      this.setState({dialogSiteVisible: false});
  }

  saveSite(siteDetail) {
    siteDetail.company = this.state.detail.id
    var promise;
    if (!siteDetail.id) {
      promise = this.Api.sites.create(siteDetail)
    } else {
      promise = this.Api.sites.update(siteDetail.id, siteDetail)
    }
    // either way, bail the dialog and refresh the page
    promise.then(() => {
      this.hideDialogSite();
      this.loadData();
    }).catch((error) => {
      processError(error, this);
    });
  }

  deleteSite(detail) {
    this.Api.sites.delete(detail.id)
      .then(() => this.loadData())
      .catch((error) => {
        processError(error, this);
      });
  }


////////////////////////////////////////////////////////////////////////
// Machines

  showDialogMachine(machineDetail) {
    // transform the hose_expiry date string value to an actual date,
    // ready for the calendar widget :|
    if (machineDetail?.hose_expiry) {
      machineDetail.hose_expiry = moment(machineDetail.hose_expiry).toDate() || ''
    }
    this.setState({
      dialogMachineVisible: true,
      machineDetail: machineDetail || {id: null}
    });
  }

  hideDialogMachine(event) {
    this.setState({dialogMachineVisible: false});
  }

  saveMachine(machineDetail) {

    var promise;

    // transform the hose_expiry date object back to a string for sending to the backend
    if (machineDetail.hose_expiry) {
      machineDetail.hose_expiry = moment(machineDetail.hose_expiry).format('YYYY-MM-DD');
    }
    if (!machineDetail.id) {
      promise = this.Api.machines.create(machineDetail)
    } else {
      promise = this.Api.machines.update(machineDetail.id, machineDetail)
    }
    // either way, bail the dialog and refresh the page
    promise.then(() => {
      this.hideDialogMachine();
      this.loadData();
    }).catch((error) => {

      // expect a machine number collision, and interpret the Django ValidationError
      // format here: {'field-name': ['error1', 'error2']}
      if (error.number && error.number[0] === 'machine with this number already exists.') {
        this.toast.show({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Un machine avec cette numero existe déjà.',
          life: 5000,
        });
        return;
      }

      processError(error, this);
    });
  }

  deleteMachine(detail) {
    this.Api.machines.delete(detail.id)
      .then(() => this.loadData())
      .catch((error) => {
        processError(error, this);
      });
  }

  /*
  format numbers with commas separating thousands and no decimal places
  */
  numberTemplate(rowData, column) {
    var value = ''
    if (rowData[column.field]) {
      value = rowData[column.field].toLocaleString(undefined, {maximumFractionDigits:0});
    }
    return <span>{ value }</span>
  }

  render() {
    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <DialogCompany
          visible={this.state.dialogCompanyVisible}
          detail={this.state.detail}
          onHide={this.hideDialogCompany}
          saveCompany={this.saveCompany}
          key={v1()}
        />

        <DialogUser
          visible={this.state.dialogUserVisible}
          detail={this.state.userDetail}
          onHide={this.hideDialogUser}
          saveUser={this.saveUser}
          key={v1()}
        />

        <DialogPassword
          visible={this.state.dialogPasswordVisible}
          detail={this.state.passwordDetail}
          onHide={this.hideDialogPassword}
          savePassword={this.savePassword}
          key={v1()}
        />

        <DialogSite
          visible={this.state.dialogSiteVisible}
          detail={this.state.siteDetail}
          onHide={this.hideDialogSite}
          saveSite={this.saveSite}
          key={v1()}
        />

        <DialogMachine
          visible={this.state.dialogMachineVisible}
          detail={this.state.machineDetail}
          onHide={this.hideDialogMachine}
          saveMachine={this.saveMachine}
          sites={this.state.detail.sites}
          key={v1()}
        />

        <div className='table-title'>
          <label>Entreprise: {this.state.detail.name}</label>

          <Button onClick={() => this.showDialogCompany()}
              type="button" icon="pi pi-pencil"
              className="p-button-default table-button"
              style={{marginLeft: '15px'}}></Button>
        </div>

        <div className='p-grid'>
          <div className="p-col-12"><label></label></div>
          <div className="p-col-12">
            <img src={this.state.logoUrl} alt='Logout' style={{width: '300px'}}/><br/>

            <FileUpload mode="basic" name="logo"
              url={this.Api.companies.getLogoUrl(this.state.detail.id)} accept="image/*.png"
              onUpload={this.onUploadLogo} auto={true} chooseLabel="Choisir logo.." />
          </div>
        </div>

        <div className='table-title'>
          <label>Users</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogUser()} icon="pi pi-plus" />
          </div>
        </div>
        <DataTable value={this.state.detail.users} responsive={true}>
          <Column field="username" header="Username" sortable={true} />
          <Column field="first_name" header="Prenom" sortable={true} />
          <Column field="last_name" header="Nom" sortable={true} />
          <Column field="email" header="Email" sortable={true} />
          <Column field="phone" header="Phone" />
          <Column field="id" header="" className="tools-column" style={{width: '150px', textAlign: 'center'}} body={(rowdata) =>
            <div>
              <Button onClick={() => this.showDialogUser(rowdata)}
                  type="button" icon="pi pi-pencil" tooltip="Modifier"
                  className="p-button-default table-button"></Button>
              <Button onClick={() => this.showDialogPassword(rowdata)}
                      type="button" icon="pi pi-lock" tooltip="Changer le mot de passe"
                      className="p-button-default table-button"></Button>
              <Button onClick={() => this.deleteUser(rowdata)}
                  type="button" icon="pi pi-trash"
                  className="p-button-danger table-button"></Button>
            </div>
          }/>
        </DataTable>

        <div className='table-title'>
          <label>Sites</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogSite()} icon="pi pi-plus" />
          </div>
        </div>
        <DataTable value={this.state.detail.sites} responsive={true}>
          <Column field="name" header="Nom" sortable={true} />
          <Column field="id" header="" className="tools-column" body={(rowdata) =>
            <div>
              <Button onClick={() => this.showDialogSite(rowdata)}
                  type="button" icon="pi pi-pencil"
                  className="p-button-default table-button"></Button>
              <Button onClick={() => this.deleteSite(rowdata)}
                  type="button" icon="pi pi-trash"
                  className="p-button-danger table-button"></Button>
            </div>
          }/>
        </DataTable>

        <div className='table-title'>
          <label>Machines</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogMachine()} icon="pi pi-plus" />
          </div>
        </div>
        <DataTable value={this.state.detail.machines} responsive={true}>
          <Column field="site__name" header="Nom de site" sortable={true} />
          <Column field="number" header="Numéro" sortable={true} />
          <Column field="volume_additive_annual_1" header="Volume additif annuel 1" sortable={true}/>
          <Column field="volume_additive_annual_2" header="Volume additif annuel 2" sortable={true}/>
          <Column field="volume_summed" header="Volume sommé" sortable={true} body={this.numberTemplate.bind(this)}/>
          <Column field="volume_additive_summed_1" header="Volume additif 1 sommé" sortable={true} body={this.numberTemplate.bind(this)}/>
          <Column field="volume_additive_summed_2" header="Volume additif 2 sommé" sortable={true} body={this.numberTemplate.bind(this)}/>
          <Column field="id" header="" className="tools-column" body={(rowdata) =>
            <div>
              <Button onClick={() => this.showDialogMachine(rowdata)}
                  type="button" icon="pi pi-pencil"
                  className="p-button-default table-button"></Button>
              <Button onClick={() => this.deleteMachine(rowdata)}
                  type="button" icon="pi pi-trash"
                  className="p-button-danger table-button"></Button>
            </div>
          }/>
        </DataTable>

      </div>
    );
  }
}

export default CompanyDetail
