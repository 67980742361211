import React from 'react';

import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Password} from 'primereact/password';

class DialogPassword extends React.Component {

  constructor(props) {
    super(props);
    var defaultModel = {
      id: null,
      password: ''
    }
    this.state = Object.assign({}, defaultModel, props.detail);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);

  }


  inputChangeHandler(event) {
    this.setState( { [event.target.name]  : event.target.value } );
  }

  render() {
    // ensure required fields
    const isValid = this.state.password.length > 2 ;

    const footer = (
      <div>
        <Button label="Save" icon="pi pi-check" disabled={!isValid}
          onClick={() => this.props.savePassword(this.state)}/>
        <Button label="Cancel" icon="pi pi-times"
          onClick={this.props.onHide} className="p-button-secondary" />
      </div>
    );

    return (
      <div>

        <Dialog
          header="Mot de passe"
          onHide={this.props.onHide}
          visible={this.props.visible}
          className={"dialog"}
          footer={footer}>

          <span className="p-float-label dialog-field">
            <Password name='password' value={this.state['password']}
              onChange={this.inputChangeHandler} feedback={false}/>
            <label htmlFor='password'>Mot de passe</label>
          </span>

        </Dialog>

      </div>
    );
  }
}

export default DialogPassword
